import { css, keyframes } from '@emotion/react';
import React, { Fragment, useState } from 'react';
import { Button, Carousel, Col, Modal, Row } from 'react-bootstrap';
import { a, useTrail } from 'react-spring';

import styled from '@emotion/styled';
import { ImCircleLeft } from '@react-icons/all-files/im/ImCircleLeft';
import { ImCircleRight } from '@react-icons/all-files/im/ImCircleRight';
import { graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import ReactMarkdown from 'react-markdown';
import SEO from '../components/seo';

const grayscale = keyframes`
  0% {
    filter: grayscale(1);
  }

  100% {
    filter: grayscale(0);
  }
`;

const InfoBox = styled(Row)`
  min-height: 75vh;
  background-attachment: fixed;
  background-image: ${(props) =>
    props.background ? `url(${props.background})` : 'none'};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  animation: ${(props) => (props.background ? grayscale : 'none')} 3s ease;
`;

const rightBottomPrimaryRing = css`
  background-image: radial-gradient(
    circle,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 39%,
    var(--primary) 40%,
    var(--primary) 60%,
    rgba(0, 0, 0, 0) 61%,
    rgba(0, 0, 0, 0) 100%
  );
  background-repeat: no-repeat;
  background-position: calc(100% + 75px) calc(100% + 75px);
  background-size: 150px 150px;
`;

const rightBottomSecondaryRing = css`
  background-image: radial-gradient(
    circle,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 39%,
    rgba(43, 43, 43, 1) 40%,
    rgba(43, 43, 43, 1) 60%,
    rgba(0, 0, 0, 0) 61%,
    rgba(0, 0, 0, 0) 100%
  );
  background-repeat: no-repeat;
  background-position: calc(100% + 75px) calc(100% + 75px);
  background-size: 150px 150px;
`;

const leftBottomSecondaryRing = css`
  background-image: radial-gradient(
    circle,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 39%,
    rgba(43, 43, 43, 1) 40%,
    rgba(43, 43, 43, 1) 60%,
    rgba(0, 0, 0, 0) 61%,
    rgba(0, 0, 0, 0) 100%
  );
  background-repeat: no-repeat;
  background-position: -75px calc(100% + 75px);
  background-size: 150px 150px;
`;

const rightTopPrimaryRing = css`
  background-image: radial-gradient(
    circle,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 39%,
    var(--primary) 40%,
    var(--primary) 60%,
    rgba(0, 0, 0, 0) 61%,
    rgba(0, 0, 0, 0) 100%
  );
  background-repeat: no-repeat;
  background-position: calc(100% + 75px) -75px;
  background-size: 150px 150px;
`;

const rightTopSecondaryRing = css`
  background-image: radial-gradient(
    circle,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 39%,
    rgba(43, 43, 43, 1) 40%,
    rgba(43, 43, 43, 1) 60%,
    rgba(0, 0, 0, 0) 61%,
    rgba(0, 0, 0, 0) 100%
  );
  background-repeat: no-repeat;
  background-position: calc(100% + 75px) -75px;
  background-size: 150px 150px;
`;

const rightTopGreyRing = css`
  background-image: radial-gradient(
    circle,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 34%,
    rgba(233, 233, 233, 1) 35%,
    rgba(233, 233, 233, 1) 65%,
    rgba(0, 0, 0, 0) 66%,
    rgba(0, 0, 0, 0) 100%
  );
  background-repeat: no-repeat;
  background-position: calc(100% + 150px) -150px;
  background-size: 300px 300px;
`;

const rightBottomGreyRing = css`
  background-image: radial-gradient(
    circle,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 34%,
    rgba(233, 233, 233, 1) 35%,
    rgba(233, 233, 233, 1) 65%,
    rgba(0, 0, 0, 0) 66%,
    rgba(0, 0, 0, 0) 100%
  );
  background-repeat: no-repeat;
  background-position: calc(100% + 200px) calc(100% + 200px);
  background-size: 400px 400px;
`;

const RightIllustration = styled(Col)`
  background-image: radial-gradient(
      circle,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 39%,
      rgba(43, 43, 43, 1) 40%,
      rgba(43, 43, 43, 1) 60%,
      rgba(0, 0, 0, 0) 61%,
      rgba(0, 0, 0, 0) 100%
    ),
    url(${(props) => props.background});
  background-repeat: no-repeat;
  background-position: -75px calc(100% + 75px), center center;
  background-size: 150px 150px, cover;
  min-height: 55vh;
`;

const heavyText = css`
  font-weight: bold;
`;

const decoratedText = css`
  border-bottom: 8px solid var(--primary);
`;

const Decorator = styled.div`
overflow        : hidden;
padding         : 40px 24px;
background-image: radial-gradient(
    circle,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 59%,
    rgba(200,200,200, 1) 60%,
    rgba(200,200,200, 1) 60%,
    rgba(0, 0, 0, 0) 61%,
    rgba(0, 0, 0, 0) 100%
  );
  background-repeat  : no-repeat;
  background-position: 80px 15px;
  background-size    : 200px 200px;
  &:                 : before {
    content      : '';
    width        : 197px;
    height       : 197px;
    border-width : 10px;
    border-style : solid;
    border-radius: 50%;
    display      : block;
    position     : absolute;
    right        : calc(50% - 142px);
    border-color : #FAFAFA transparent transparent transparent;
    transform    : rotate(5deg);
    top          : calc(50% - 78px);
  }
  &:                 : after {
    content      : '';
    width        : 195px;
    height       : 195px;
    border-width : 15px;
    border-style : solid;
    border-radius: 50%;
    display      : block;
    position     : absolute;
    right        : calc(50% - 142px);
    border-color : #FAFAFA transparent transparent transparent;
    transform    : rotate(185deg);
    top          : calc(50% - 95px);
  }
`;

const GrandMain = styled.h1`
  padding: 24px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`;

const GrandText = styled(a.div)`
  position: relative;
  width: 100%;
  will-change: transform, opacity;
  overflow: hidden;
  & > div {
    overflow: hidden;
  }
`;

const carousel = css`
  & .carousel-indicators li {
    background-color: var(--primary);
  }
  & .carousel-control-next,
  .carousel-control-prev {
    color: var(--primary);
    font-size: 1.2rem;
  }
`;

const GrandTitle = ({ children, ...props }) => {
  const items = React.Children.toArray(children);
  const trail = useTrail(items.length, {
    config: { mass: 100, tension: 5000, friction: 750 },
    opacity: 1,
    x: 0,
    height: 50,
    from: { opacity: 0, x: 50, height: 0 },
  });

  return (
    <GrandMain {...props}>
      <div>
        {trail.map(({ x, height, ...rest }, index) => (
          <GrandText
            key={index}
            style={{
              ...rest,
              transform: x.interpolate((x) => `translate3d(${-x}px,0,0)`),
            }}>
            <a.div style={{ height }}>{items[index]}</a.div>
          </GrandText>
        ))}
      </div>
    </GrandMain>
  );
};

const Anchor = ({ children, ...props }) => <a {...props}>{children}</a>;
const IndexPage = ({ data, location }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  return (
    <>
      <SEO title='Selamat datang' />
      <Carousel
        fade
        css={carousel}
        nextIcon={<ImCircleRight />}
        prevIcon={<ImCircleLeft />}>
        <Carousel.Item>
          <InfoBox
            background={
              data.files.edges.find(({ node: { name } }) => name === 'index-1')
                .node.childImageSharp.fluid.src
            }>
            <Col
              sm={'6'}
              md={'5'}
              className='d-flex flex-column justify-content-center align-items-center align-content-center text-white'
              css={[
                rightBottomPrimaryRing,
                { backgroundColor: 'rgb(43,43,43,0.9)' },
              ]}>
              <GrandTitle css={heavyText}>
                <div>Service</div>
                <div>excellence</div>
                <div>
                  with <span className='text-primary'>smile</span>
                </div>
              </GrandTitle>
            </Col>
          </InfoBox>
        </Carousel.Item>
        {data.sliders.edges.map(
          ({ node: { cockpitId, name, photo, link } }) => {
            const Component = link && link.value ? Anchor : Fragment;
            return (
              <Carousel.Item key={cockpitId} className='bg-secondary'>
                <Component href={link ? link.value : null}>
                  <Img
                    fluid={photo.value.childImageSharp.fluid}
                    objectFit='contain'
                    css={{ height: '75vh' }}
                  />
                </Component>
              </Carousel.Item>
            );
          }
        )}
      </Carousel>
      <InfoBox>
        <Col
          sm={'6'}
          md={'5'}
          className='d-flex flex-column justify-content-center align-items-center align-content-center bg-secondary'
          css={rightTopSecondaryRing}>
          <Decorator className='m-2'>
            <h2
              className='pb-2 pt-4 mb-3 mt-3'
              css={[heavyText, decoratedText]}>
              <div>Profil RSGM</div>
              <div>IIK Bhakti Wiyata</div>
            </h2>
          </Decorator>
        </Col>
        <Col
          className='d-flex flex-column justify-content-center align-items-center align-content-center p-3 pb-5 pt-5 p-md-5 bg-white'
          css={rightTopGreyRing}>
          <ReactMarkdown
            source={data.profile.content.value}
            escapeHtml={false}
          />
        </Col>
      </InfoBox>
      <InfoBox css={{ minHeight: '55vh' }}>
        <Col
          sm={'6'}
          className='d-flex flex-column justify-content-center align-items-center align-content-center bg-dark text-white p-3 pb-5 pt-5 p-md-5'
          css={rightBottomPrimaryRing}>
          <h3 className='text-primary'>Visi</h3>
          <ReactMarkdown
            source={data.vision.content.value}
            escapeHtml={false}
          />
          <h3 className='text-primary'>Misi</h3>
          <ReactMarkdown
            source={data.mission.content.value}
            escapeHtml={false}
          />
        </Col>
        <RightIllustration
          sm={'6'}
          background={
            data.files.edges.find(({ node: { name } }) => name === 'index-2')
              .node.childImageSharp.fluid.src
          }
        />
      </InfoBox>
      <InfoBox>
        <Col
          sm={'6'}
          md={'5'}
          className='d-flex flex-column justify-content-center align-items-center align-content-center bg-secondary p-5'
          css={rightBottomSecondaryRing}>
          <h2 align='center'>
            <div>Sambutan Direktur</div>
            <div>RSGM IIK Bhakti Wiyata</div>
          </h2>
          <div className='mt-4 mb-4'>
            <Img fixed={data.opening.photo.value.childImageSharp.fixed} />
          </div>
          <h5 align='center'>{data.opening.director.value}</h5>
        </Col>
        <Col
          className='d-flex flex-column justify-content-center align-items-center align-content-center p-3 pb-5 pt-5 p-md-5 bg-white'
          css={rightBottomGreyRing}>
          <ReactMarkdown
            source={data.opening.content.value}
            escapeHtml={false}
          />
        </Col>
      </InfoBox>
      <InfoBox className='bg-white'>
        <Col
          xs={'12'}
          className='py-5 d-flex flex-column align-items-stretch align-content-center'
          css={[rightTopPrimaryRing, { justifyContent: 'space-evenly' }]}>
          <h3 align='center' className='text-primary mb-2 font-weight-bold'>
            Kelebihan RSGM IIK BW
          </h3>
          {/* <Row>
            <Col></Col>
            <Col xs={'8'} sm={'10'}>
              <Row>
                {data.numbers.edges.map(
                  ({ node: { cockpitId, name, photo } }) => (
                    <Col
                      sm        = {'4'}
                      key       = {cockpitId}
                      className = 'd-flex flex-column justify-content-center align-items-center align-content-center p-2'>
                      <Img
                        fixed     = {photo.value.childImageSharp.fixed}
                        className = 'mb-3'
                      />
                      <h4 align='center'>{name.value}</h4>
                    </Col>
                  )
                )}
              </Row>
            </Col>
            <Col></Col>
          </Row> */}
          <Carousel
            fade
            css={carousel}
            nextIcon={<ImCircleRight />}
            prevIcon={<ImCircleLeft />}>
            {[...Array(Math.ceil(data.numbers.edges.length / 3)).keys()].map(
              (i, idx) => (
                <Carousel.Item key={idx}>
                  <Row>
                    <Col></Col>
                    <Col xs={'8'} sm={'10'}>
                      <Row css={{ minHeight: '40vh' }} className='pt-3 pb-3'>
                        {data.numbers.edges
                          .slice(
                            idx * 3,
                            Math.min(idx * 3 + 3, data.numbers.edges.length)
                          )
                          .map(({ node: { cockpitId, name, photo } }) => (
                            <Col
                              sm={'4'}
                              key={cockpitId}
                              className='d-flex flex-column justify-content-center align-items-center align-content-center p-2'>
                              <Img
                                fixed={photo.value.childImageSharp.fixed}
                                className='mb-3'
                              />
                              <h4 align='center'>{name.value}</h4>
                            </Col>
                          ))}
                      </Row>
                    </Col>
                    <Col></Col>
                  </Row>
                </Carousel.Item>
              )
            )}
          </Carousel>
        </Col>
      </InfoBox>
      {(data.popup || data.popup_image) && (
        <Modal show={show} onHide={handleClose} size='lg' centered scrollable>
          <Modal.Header closeButton>
            <Modal.Title>
              {(data.popup || data.popup_image).title.value}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {data.popup_image ? (
              <Img
                fluid={data.popup_image.photo.value.childImageSharp.fluid}
                className='mb-3'
              />
            ) : (
              <ReactMarkdown
                source={data.popup.content.value}
                escapeHtml={false}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>
              Tutup
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export const query = graphql`
  {
    profile: cockpitProfile {
      content {
        type
        value
      }
    }
    opening: cockpitOpening {
      content {
        type
        value
      }
      director {
        value
      }
      photo {
        type
        value {
          childImageSharp {
            fixed(width: 300, toFormat: WEBP) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
    vision: cockpitVision {
      content {
        type
        value
      }
    }
    mission: cockpitMission {
      content {
        type
        value
      }
    }
    numbers: allCockpitNumbers(filter: { display: { value: { eq: true } } }) {
      edges {
        node {
          cockpitId
          name {
            value
          }
          photo {
            value {
              childImageSharp {
                fixed(toFormat: WEBP) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
      }
    }
    popup: cockpitHomepopup(display: { value: { eq: true } }) {
      title {
        value
      }
      content {
        type
        value
      }
    }
    popup_image: cockpitHomepopupimage(display: { value: { eq: true } }) {
      title {
        value
      }
      photo {
        value {
          childImageSharp {
            fluid(toFormat: WEBP) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    sliders: allCockpitPrimarysliders(
      filter: { display: { value: { eq: true } } }
      sort: {fields: id, order: DESC}
    ) {
      edges {
        node {
          cockpitId
          name {
            value
          }
          link {
            value
          }
          photo {
            value {
              childImageSharp {
                fluid(maxWidth: 1920, toFormat: WEBP) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    files: allFile(filter: { name: { in: ["index-1", "index-2"] } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(toFormat: WEBP, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
